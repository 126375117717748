import React, { Component } from "react";
import {
  Box,
  Paper,
  Container,
  Typography,
  Switch,
  Divider,
  Tab,
  Tabs,
  Button,
  Grid2,
  Card,
  Autocomplete
} from "@mui/material";
import Slider from "@mui/material/Slider";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SearchIcon from '@mui/icons-material/Search';
import three from '../img/three.jpg'
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import Chip from '@mui/material/Chip';
import {TextField} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import AppBarWithSearch from "./AppBarWithSearch";
import Autocompletee from "react-google-autocomplete";
import Appbar from "./Appbar";
///AIzaSyDg2LA8IAl3SPgxqiAt1FBNb54Z8jk4kZ0
const api_key = "AIzaSyDg2LA8IAl3SPgxqiAt1FBNb54Z8jk4kZ0"

const MAX = 100;
const MIN = 0;
const marks = [
  {
    value: MIN,
    label: "",
  },
  {
    value: MAX,
    label: "",
  },
];

export class Properties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slider_value: 70,
      tab_value: "1",


      multi_seach_data : ["1 RESIDENTIAL PLOT","1 RESIDENTIAL LAND","1 BHK"]
    };
  }

  render() {
    return (
      <div>
        <Appbar/>
        <Box sx={{ backgroundColor: "#f8f9fa", minHeight: 800, width: "100%" }}>
          <Container maxWidth="xxl">
            <br />
            <br />
            <br />

            <Box
              sx={{ ml: { xs: 0, sm: 0, md: 3 }, mr: { xs: 0, sm: 0, md: 3 } }}
            >
              <Grid2 container spacing={2}>
                <Grid2 sx={{display:{xs:"none",sm:'none',md:'block'}}} size={{xs:12,sm:12,md:3}} item>
                  <Card
                    sx={{
                      width: "100%",
                      minHeight: 600,
                      backgroundColor: "#fff",
                     border: 1,
                      borderColor: "#ebebf5",
                      borderRadius: 1,
                    }}
                  >
                    <Box sx={{ padding: 3 }}>

                     <Box>



  
  <Autocomplete
  sx={{fontSize:10,mr:1}}
  ListboxProps={{
    sx: { fontSize:12,fontWeight:'600',maxHeight:150 },
    }}
  fullWidth
          multiple
          id="tags-filled"
          limitTags={5}
          onChange={(e,data)=>{this.setState({multi_seach_data:data})}}
          value={this.state.multi_seach_data}
          options={this.state.multi_seach_data.map((option) => option )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip sx={{height:23,fontSize:10,fontWeight:'600',backgroundColor:'#eff0f0'}} variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{width:'100%'}}
              variant="standard"
              size='small'
              fullWidth
            />
          )}
          
        />
   

      



                     </Box>

<br/>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                          mb: 1.5,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 16,
                            color: "black",
                           fontWeight: "600",
                            //fontFamily: "Roboto",
                          }}
                        >
                          Hide Already Seen
                        </Typography>
                        <Switch size="small" sx={{ color: "#e1e5ff" }} />
                      </Box>

                      <Divider />

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1.5,
                          mb: 0.5,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 16,
                            color: "black",
                           fontWeight: "600",
                            //fontFamily: "Roboto",
                          }}
                        >
                          Verified Properties
                        </Typography>
                        <Switch size="small" sx={{ color: "#e1e5ff" }} />
                      </Box>

                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{
                            fontSize: 12,
                            color: "#fff",
                            backgroundColor: "#00a3ff",
                            width: 65,
                            pl: 0.5,
                            pr: 0.5,
                            fontWeight: "600",
                            fontFamily: "Roboto",
                            mb: 1,
                          }}
                        >
                          <DoneAllIcon
                            sx={{ height: 15, width: 15, mr: 0.3 }}
                          />
                          Verified
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: 11,
                            color: "#a4a4a4",
                            pt: 0.5,
                            pl: 1.5,
                            pr: 0.5,
                            
                            fontFamily: "Roboto",
                            mb: 1,
                          }}
                        >
                          Verified by incc team
                        </Typography>
                      </Box>

                      <Divider />

                      <br />
                      <Typography
                        sx={{
                          fontSize: 16,
                          color: "black",
                          fontWeight: "600",
                          
                        }}
                      >
                        Budget
                      </Typography>
                      <br />
                      <br />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: -1.5,
                        }}
                      >
                        <Box
                          sx={{
                            height: 20,
                            width: 30,
                            backgroundColor: "#00c6c6",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 13,
                              fontWeight: "800",
                              textAlign: "center",
                              color: "#fff",
                            }}
                          >
                            0 L
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: "#00c6c6",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 13,
                              fontWeight: "800",
                              textAlign: "center",
                              color: "#fff",
                            }}
                          >
                            10 Cr
                          </Typography>
                        </Box>
                      </Box>

                      <Slider
                        size="small"
                        defaultValue={this.state.slider_value}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        min={0}
                        max={1000}
                        color="primary"
                        onChange={(e) => {
                          this.setState({
                            slider_value: e.target.value,
                          });
                        }}
                        valueLabelFormat={`${this.state.slider_value} LAKH`}
                      />


<Divider/>

<Box sx={{mt:2,mb:2}}>
<Typography sx={{fontSize:17,fontWeight:'600',color:'black',mb:2}}>Type Of Property</Typography>

<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>1 RESIDENTIAL APPARTMENT</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>1 RESIDENTIAL LAND</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>1 INDEPENDENT HOUSE/VILLA</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>1 INDEPENDENT/BUILDER FLOOR</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>1 RK STUDIO APPARTMENT</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>FARM HOUSE</Button>



</Box>




<Divider/>

<Box sx={{mt:2,mb:2}}>
<Typography sx={{fontSize:17,fontWeight:'600',color:'black',mb:2}}>No. of Bedrooms</Typography>

<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mr:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>1 BHK</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1,mr:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>2 BHK</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1,mr:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>3 BHK</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1,mr:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>4 BHK</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1,mr:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>5 BHK</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1,mr:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>6 BHK  </Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1,mr:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>7 BHK</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1,mr:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>8 BHK</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1,mr:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>9 BHK + </Button>


</Box>





<Divider/>

<Box sx={{mt:2,mb:2}}>
<Typography sx={{fontSize:17,fontWeight:'600',color:'black',mb:2}}>Construction Status</Typography>

<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mr:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>1 NEW LAUNCH</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1,mr:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>2 UNDER CONSTRUCTION</Button>
<Button variant="outlined" size="small" sx={{height:27,fontSize:11,borderRadius:3,mt:1,mr:1}} startIcon={<AddIcon sx={{mr:-0.5,height:13,width:13}}/>}>3 READY TO MOVE</Button>


</Box>
















                    </Box>
                  </Card>
                </Grid2>

                {
                  ///// property  list and short list data
                }

                <Grid2 item  size={{xs:12,sm:12,md:9}} >
                  <Box>

                   

                    <Paper elevation={1} sx={{height:50,width:'100%',mt:1,mb:1,display:'flex',justifyContent:'space-between'}}>
  <Box sx={{height:50,width:'100%',display:'flex',}}>
<SearchIcon sx={{mt:1.8,color:'#a4a4a4',ml:1}}/> 
<Autocompletee
  apiKey={api_key}
  onPlaceSelected={(place) => this.setState({ location_name:place? place.formatted_address : ""})}
  style={{borderRadius:5,border:'none',borderColor:'white',outline:'none',width:'100%'}}
/>
  </Box>

  <Box sx={{height:50,width:'100%',display:'flex',justifyContent:'right',alignItems:'center'}}>
  <Button size="small" variant="contained" sx={{height:30,mr:1}} disableElevation endIcon={<SearchIcon />}>
  Search
  </Button>
</Box>
                    </Paper>


                    <Paper elevation={1}>
                      <Tabs
                         allowScrollButtonsMobile={true}
                        value={this.state.tab_value}
                        onChange={(e) =>
                          this.setState({ tab_value: e.target.value })
                        }
                        aria-label="wrapped label tabs example"
                      >
                        <Tab value="1" label="posted by Owner" wrapped />
                        <Tab value="2" label="Verified" wrapped/>
                        <Tab value="3" label="Under Construction" wrapped/>
                        <Tab value="4" label="Ready To Move" wrapped />
                        <Tab value="5" label="With photo and video" wrapped/>
                      </Tabs>
                    </Paper>



{
  ////// desktop view property list
}



{
[1,2,3,4,5,6,7].map(()=>(
  <Box sx={{width:'100%'}} onClick={()=>window.open('/Properties/description')}>
    <Card
                      sx={{
                        //width: "100%",
                        minHeight: 240,
                        backgroundColor: "#fff",
                        borderRadius: 1,
                        border:1, 
                        borderColor: "#eff0f0",
                        mt:2,
                       // display:'flex',
                      }}
                    >

<Grid2 container spacing={1}>
<Grid2 item  size={{xs:12,sm:12,md:4}} >
   <Box sx={{backgroundImage:`url(${three})`,height:300, width:'100%' ,backgroundRepeat: 'no-repeat',backgroundPosition:'center',backgroundSize:'cover',borderRadius:2}}>
                         <Typography sx={{fontSize:10}}>.</Typography>
                         <Box sx={{height:35,width:35,backgroundColor:'rgb(255,255,255,0.8)',borderRadius:9,ml:1}}>
                           <Checkbox size="small"  icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
                         </Box>
                     
{
  //// at image button and another statics data will display
}

<Box sx={{display:'flex',flexDirection:'column',justifyContent:'flex-end',height:'80%'}}>
<Typography sx={{fontSize:11,fontWeight:'500',color:'#fff',display:'flex',ml:2}}>7 VIDEOS | 7 IMAGES</Typography>
</Box>

                      </Box>
                      
</Grid2>

<Grid2 item size={{xs:12,sm:12,md:8}} >
  <Box sx={{backgroundColor:'#fff'}}>
    <br/>
<Typography sx={{fontSize:16,fontWeight:'700',pl:2,color:'#010c1f',pr:2}}>Kohinoor Presidentia</Typography>
<Typography sx={{fontSize:11,fontWeight:'400',pl:2,color:'#a0a0a0',pr:2}}>2 BHK Flat in BT Kawade Road, Pune, <Button size="small" disableElevation sx={{height:17,fontSize:10,ml:0.5}} variant="contained" endIcon={<DoneAllIcon sx={{height:13,width:13,color:'#ab266c'}}/>}>
  Rera
</Button></Typography>

<Box sx={{display:'flex',flexDirection:'row',mt:2,mb:2}}>
  <Box sx={{borderRight:1,borderColor:'#a0a0a0',minWidth:110,height:50}}>
<Typography sx={{fontSize:16,fontWeight:'700',pl:2,pr:2,color:'#010c1f'}}>3.75 Cr</Typography>
<Typography sx={{fontSize:13,fontWeight:'500',pl:2,pr:2,color:'#666666'}}>2083 sqft</Typography>

  </Box>
  <Box sx={{borderRight:1,borderColor:'#a0a0a0',minWidth:130,height:50}}>

  <Typography sx={{fontSize:16,fontWeight:'700',pl:2,pr:2,color:'#010c1f'}}>13,20 sqft</Typography>
  <Typography sx={{fontSize:13,fontWeight:'500',pl:2,pr:2,color:'#666666'}}>Plot area</Typography>

  </Box>
  <Box sx={{width:170,height:50}}>

  <Typography sx={{fontSize:16,fontWeight:'700',color:'#010c1f',ml:2,pr:2}}>3 BHK</Typography>
  <Typography sx={{fontSize:13,fontWeight:'500',color:'#666666',ml:2,pr:2}}>Ready To Move</Typography>

  </Box>

</Box>

<Box>
<Typography sx={{
  fontSize:13,
  fontWeight:'500',
  textAlign:'justify',
   ml:2,
  overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    pr:2
  }}>Address-59/2, saraswathipuram,new extension, near jogupalaya community hall, halasuru, 560008 Architect,  near jogupalaya community hall, halasuru, 560008 Architect</Typography>
</Box>

<Typography sx={{fontSize:14,fontWeight:'600',color:'#66b4da',display:'flex',mt:2,pl:2}}>TYPE : <Typography sx={{color:'#e60278',fontSize:14,fontWeight:'600',ml:1}}>FLATS</Typography></Typography>
<Box sx={{width:'100%',display:'flex',justifyContent:'right',alignContent:'right',mt:2,mb:2}}>
<Button variant="outlined" disableElevation size="small" sx={{mr:2,fontSize:12}}>Contact</Button>
<Button variant="contained" disableElevation size="small" sx={{mr:2,fontSize:12}}>View Number</Button>
</Box>


  </Box>
</Grid2>

</Grid2>



    </Card>
    </Box>
))

}
                  







                  </Box>
                </Grid2>


              </Grid2>
            </Box>
          </Container>
        </Box>
      </div>
    );
  }
}

export default Properties;



const country= ['Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'The Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of the',
  'Congo, Republic of the',
  'Costa Rica',
  'Côte d’Ivoire',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor (Timor-Leste)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'The Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Sudan, South',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe'];