import React, { useState } from "react";
import { Box, Button,Divider,Grid2,Link,Menu,MenuItem, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function Test() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let [tab,SetTab] = useState('home')



  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>

    
      <Button
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        sx={{fontSize:15,mr:2,fontWeight:'bold',textTransform:'none',color:'black'}}
        onClick={handleClick}
        endIcon={<ExpandMoreIcon sx={{height:23,width:23,ml:-1}}/>}
        onMouseOver={handleClick}
      >
       For Buyers
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        <Box sx={{height:300,width:750,display:'flex',justifyContent:'space-between'}}>
       
        <Box sx={{height:300,width:230,backgroundColor:'#f4f4f6',p:0,display:'flex',flexDirection:'column'}}>
        
             <Link  sx={{fontSize:14,fontWeight:'700',textTransform:'uppercase',mt:2,ml:3,color:'#072743'}} onClick={()=>SetTab('home')}>BUY HOME</Link>
             <Link  sx={{fontSize:14,fontWeight:'550',textDecoration:'none',color:'#071b40',mt:1,ml:3}} onClick={()=>SetTab('plot')}>Land / Plot</Link>
             <Link  sx={{fontSize:14,fontWeight:'550',textDecoration:'none',color:'#071b40',mt:1,ml:3}} onClick={()=>SetTab('commercial')}>COMERCIAL</Link>
             <Link  sx={{fontSize:14,fontWeight:'550',textDecoration:'none',color:'#071b40',mt:1,ml:3}} onClick={()=>SetTab('popular_area')} >POPULAR AREAS</Link>
             <Link  sx={{fontSize:14,fontWeight:'550',textDecoration:'none',color:'#071b40',mt:1,ml:3}} onClick={()=>SetTab('rental_properties')}>RENTAL PROPERTIES</Link>
             <Link  sx={{fontSize:14,fontWeight:'550',textDecoration:'none',color:'#071b40',mt:1,ml:3}} onClick={()=>SetTab('insight')}>INSIGHTS</Link>
             <Link  sx={{fontSize:14,fontWeight:'550',textDecoration:'none',color:'#071b40',mt:1,ml:3,mb:1}}> ARTICAL & NEWS</Link>
    
    <Divider/>
            <Typography sx={{fontSize:14,fontWeight:'700',mt:2,ml:3,color:'black',textTransform:'uppercase'}}>Call on this number</Typography>
            <Typography sx={{fontSize:13,fontWeight:'550',ml:3,color:'grey'}}>8888-9909-9998</Typography>
        </Box>

        <Box sx={{height:300,width:520,backgroundColor:'#fff'}}>
            
            {
              /////buy home
            }
            <Box sx={{display:tab=='home'?'block':'none'}}>
            <Grid2 container spacing={2}>
                 <Grid2 item size={{xs:6}}>
                 <Box sx={{display:'flex',flexDirection:'column'}}>
                 <Link  href='/' sx={{fontSize:14,fontWeight:'700',textTransform:'uppercase',mt:2,ml:3,color:'#072743'}}>PROPERTIES IN PUNE</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Flats</Link>
                 <Link  href='/'  sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Builder Floor</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Independent House</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Services Appartment</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Society Appartment</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Studio Apartments/1 RK Flats</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Farm Houses</Link>

                 </Box>
                 </Grid2>
                 <Grid2 item size={{xs:6}}>
                 <Box sx={{display:'flex',flexDirection:'column'}}>
                 <Link  href='/' sx={{fontSize:14,fontWeight:'700',textTransform:'uppercase',mt:2,ml:3,color:'#072743'}}>POPULAR SEARCHES</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Properties In Pune South</Link>
                 <Link  href='/'  sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Verified Properties In Pune</Link>
                 <Link  href='/'  sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>New Project In Pune South</Link>
               
                 </Box>
                 </Grid2>
            </Grid2>
            </Box>


            {
              ///// land / plot
            }
            <Box sx={{display:tab=='plot'?'block':'none'}}>
            <Grid2 container spacing={2}>
                 <Grid2 item size={{xs:6}}>
                 <Box sx={{display:'flex',flexDirection:'column'}}>
                 <Link  href='/' sx={{fontSize:14,fontWeight:'700',textTransform:'uppercase',mt:2,ml:3,color:'#072743'}}>PROPERTIES IN PUNE</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:2,ml:3}}>Gated Community Plots In Pune</Link>
                 <Link  href='/'  sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Corner Plots In Pune</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>East Facing Plot In Pune</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Freehold Plot In Pune South</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Authority Plots In Pune</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>PMRDA Authority Plots In Pune</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>RCUDA Authority Plots In Pune</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>PCNTDA Authority Plots In Pune</Link>

                 </Box>
                 </Grid2>
                 <Grid2 item size={{xs:6}}>
                 <Box sx={{display:'flex',flexDirection:'column'}}>
                


                 </Box>
                 </Grid2>
            </Grid2>
            </Box>



            {
              ///// commercial
            }
            <Box sx={{display:tab=='commercial'?'block':'none'}}>
            <Grid2 container spacing={2}>
                 <Grid2 item size={{xs:6}}>
                 <Box sx={{display:'flex',flexDirection:'column'}}>

                 <Link  href='/' sx={{fontSize:14,fontWeight:'700',textTransform:'uppercase',mt:2,ml:3,color:'#072743'}}>PROPERTIES IN PUNE</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:2,ml:3}}>Ready to Move office spaces</Link>
                 <Link  href='/'  sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Bare shell office spaces</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Co-working office spaces</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Shops</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Factory</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Warehouses</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Showrooms</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Industrial Lands/Plots</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Agricultural/Farm Land</Link>


                 </Box>
                 </Grid2>
                 <Grid2 item size={{xs:6}}>
                 <Box sx={{display:'flex',flexDirection:'column'}}>
                
                 <Link  href='/' sx={{fontSize:14,fontWeight:'700',textTransform:'uppercase',mt:2,ml:3,color:'#072743'}}> POPULAR SEARCHES</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:2,ml:3}}>Commercial property for sale in Pune South</Link>
                 <Link  href='/'  sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>Verified Commercial property for sale in Pune South</Link>
                 <Link  href='/' sx={{fontSize:13,fontWeight:'500',textDecoration:'none',color:'#071b40',mt:1,ml:3}}>New Commercial Projects in Pune South</Link>
               
                 </Box>
                 </Grid2>
            </Grid2>
            </Box>







        </Box>

        </Box>
      </Menu>



    </div>
  );
}

export default Test;