import React, { Component } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Container, Divider,Button } from '@mui/material';
import logo from "../img/logo.jpg"
import PersonIcon from '@mui/icons-material/Person';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import CssBaseline from '@mui/material/CssBaseline';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Test from "../Test";
import ww from '../ww.webp'





export class Appbar extends Component {

    constructor(props) {
      super(props)
    
      
    this.state = {
        anchorEl : null,
        open: false
      }
      this.setAnchorEl = this.setAnchorEl.bind(this)
      this.handleClick = this.handleClick.bind(this)
      this.handleClose = this.handleClose.bind(this)
    }


    handleClick(event) {
        this.setAnchorEl(event.currentTarget);
    }

        setAnchorEl(value){
            this.setState({
                anchorEl: value,
                open: !this.state.open
            })
        }

        
    handleClose() {
        this.setAnchorEl(null);
    }



    renderMenu(){
        return(
          <Box sx={{}}>
        <Menu id="fade-menu" anchorEl={this.state.anchorEl} open={this.state.open}  onClose={this.handleClose}>
              <MenuItem component='a' href='/login' onClick={this.handleClose}>Login</MenuItem>
              <MenuItem component='a' href='/register' onClick={this.handleClose}>Register</MenuItem>
              <MenuItem component='a' href='/contactus' onClick={this.handleClose}>Contact us</MenuItem> 
              <MenuItem component='a' href='/about' onClick={this.handleClose} >About Us</MenuItem>
      
          </Menu>
          </Box>
         )
      }




    
  render() {
    return (
      <div>
        <Box>
          <AppBar
            position="fixed"
            sx={{
              backgroundColor: "#ffffff",
              borderBottom: 2,
              borderBottomColor: "#c7167a",
            }}
          >
            <Container maxWidth="xl">
              <Toolbar variant="dense">
                <Box
                  sx={{ flex: 1, marginLeft: { xs: -3, sm: 0, md: 0, lg: 0 } }}
                >
                  <img
                    alt="logo"
                    src={logo}
                    style={{ height: 45, objectFit: "contain" }}
                  />
                </Box>

                <Box
                  sx={{
                    display: {xs:"block",sm:'block',md:'block'},
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                        }}
                      >
                       <Test/>
                    </Box>

                    <IconButton

                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 ,display: {xs:"none",sm:'none',md:'block'},}}
                    >
                      <AddIcCallIcon
                        sx={{ color: "black", height: "60", width: "50" }}
                      />
                    </IconButton>


                    <Button variant="contained" disableElevation   size="small" sx={{mr:2,textTransform:'none',backgroundColor:'#ab266c',fontSize:11,}}>POST A PROJECT</Button>

                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ marginRight: { xs: -3, sm: 0, md: 0, lg: 0 },display: {xs:"block",sm:'block',md:'block'}, }}
                      aria-owns={this.state.open ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      onClick={this.handleClick}
                    >
                      <MenuIcon
                        sx={{ color: "black", height: "60", width: "50" }}
                      />
                    </IconButton>
                    {this.renderMenu()}



                  </Box>

             
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
          <Divider sx={{ borderBottomWidth: 6 }} />



          <Box
              sx={{ display: "flex", justifyContent: "right" }}
              component="a"
              href="https://wa.me/+918888884174"
            >
              <Fab
                variant="circular"
                size="medium"
                aria-label="add"
                sx={{
                  position: "fixed",
                  bottom: 50,
                  right: 30,
                //  color: "white",
                 backgroundColor:'transparent'
                }}
              >
                <img src={ww} style={{height:50,width:50}}/>
              </Fab>
            </Box>


        </Box>
      </div>
    );
  }
}

export default Appbar;
