import React, { Component, useEffect, useState } from 'react'
import {Box, Card, Paper,Divider, Grid2,Typography,IconButton,Checkbox, Button} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import ltr from '../src/img/ll.jpg'
import base_url from '../src/base_url'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate,useLocation, Link } from 'react-router-dom';






function Register(props) {
  const navigate = useNavigate()
  const location = useLocation()

  const [showpassword,SetshowPassword] =useState(true);
  const [username,Setusername] =useState("");
  const [password,Setpassword] =useState("");




function login(){
/*
  fetch(`${base_url.base_url}/login/login`, {
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body:JSON.stringify({
      username : username,
      password : password
    })
  })
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      if(result.status){
         localStorage.setItem('email',username)
         loginSucessfull()
      }else{
        loginFail()
      }
    });
*/
}



function loginSucessfull(){
  toast.success('Login sucessfull', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
   theme: "colored"
   // transition: Bounce,
    });
}


function loginFail(){
  toast.error('Login Failed', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored"
    //transition: Bounce,
    });
}



  return (
    <div>
    <Box sx={{backgroundColor:"#f8f9ff",width:'100%',height:'100vh'}}>
    <Box sx={{display:'flex',justifyContent:'center'}}>
    <Box sx={{display:'flex',alignItems:'center',height:{xs:500,sm:500},width:{xs:'90%',sm:600,md:800},position:'absolute',top:80}}>
    <Card sx={{height:'100%',width:'100%',borderRadius:1}}>
    
    <Grid2 container spacing={1}>
      <Grid2 item  size={{xs:0,sm:6}}  sx={{display:{xs:'none',sm:'block'}}}>
      <Box sx={{display:'flex',justifyContent:'center'}}>
    <Box sx={{display:'flex',alignItems:'center',height:{xs:500,sm:500},width:'100%',backgroundColor:'pink'}}>
 
      <img src={ltr} style={{height:'100%',width:'100%',objectFit:'cover'}}/>
  
    </Box>
      </Box>
      </Grid2>
    
      <Grid2 item size={{xs:12,sm:6}}>
      <Box sx={{display:'flex',justifyContent:'center',width:'100%'}}>
    <Box sx={{display:'flex',alignItems:'center',width:'100%'}}>
      <Box sx={{display:'flex',flexDirection:'column',width:'100%'}}>
    
    <Box sx={{height:500,marginLeft:3,marginRight:{xs:3,sm:8}}}>
    <Typography sx={{textAlign:'left',fontSize:{xs:16,sm:20},fontWeight:'800',marginTop:9}}>Register</Typography>
    
    
    <Typography sx={{textAlign:'left',fontSize:{xs:11,sm:13},fontWeight:'600',marginTop:3,marginBottom:0.1}}>Email id</Typography>
    <Box sx={{border:1,borderRadius:1,borderColor:'#a2a2a6'}}>
    <TextField
    size='small'
    sx={{padding:0.5,"& input::placeholder": {
        fontSize: "13px"
      }}}
    fullWidth
            id="input-with-icon-textfield"
            placeholder='Email id'
            name='user_name'
            onChange={(e)=>Setusername(e.target.value)}
            value={username}
            InputProps={{
                disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon style={{color:'#a2a2a6'}} />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
    
    </Box>
    
    
    
    
    <Typography sx={{textAlign:'left',fontSize:{xs:11,sm:13},fontWeight:'600',marginTop:1,marginBottom:0.1}}>Password</Typography>
    <Box sx={{border:1,borderRadius:1,borderColor:'#a2a2a6'}}>
    <TextField
              id="filled-start-adornment"
              sx={{padding:0.5,"& input::placeholder": {
                fontSize: "13px"
              }}}
              name="password"
              value={password}
            onChange={(e)=>Setpassword(e.target.value)}
              placeholder='Your Password'
              size='small'
              fullWidth
              type= {showpassword?'text':'password'}
              InputProps={{  
                disableUnderline: true,
                endAdornment:(
                    <InputAdornment position="start">
                     {
                      showpassword? <Visibility style={{color:'#a2a2a6'}} onClick={()=>SetshowPassword(false)}/>:<VisibilityOff onClick={()=>SetshowPassword(true)} style={{color:'#a2a2a6'}}/> 
                     } 
                    </InputAdornment>
                  ),
                startAdornment: (
                    <InputAdornment position="start">
                     <LockIcon style={{color:'#a2a2a6'}} />
                    </InputAdornment>
                  ),
              }}
              variant="standard"
            />
    
    </Box>
    
    
    
    <Typography sx={{textAlign:'right',color:'red',fontSize:'13px',marginTop:2}}></Typography>
    
    <Box sx={{display:'flex',flexDirection:'row',marginTop:1,justifyContent:'left',alignItems:'center'}}>
    <Checkbox defaultChecked size='small' style={{color:'#ab266c'}}/>
    <Typography sx={{fontSize:'13px'}}>Remember me</Typography>
    </Box>
    
    
    <Box sx={{marginTop:6,marginLeft:4,marginRight:4}}>
     
        <Button   type='submit'  onClick={login}  variant="contained" fullWidth size='small' sx={{backgroundColor:'#ab266c'}}>Login</Button>
 
    </Box>
    
    
    
    
  
    
    
    </Box>
      </Box>
    </Box>
    </Box>
      </Grid2>

    </Grid2>
    </Card>
    </Box>
    </Box>

    </Box>



    <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick={false}
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
//transition={Bounce}
/>

          </div>
  )
}

export default Register


























