import React, { Component } from 'react'
import poo from "../img/pp.jpg"
import { Paper,Box,Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Container,Typography,Card,Grid,Divider,TextField,Button} from '@mui/material';
import Appbar from '../Views/Appbar'
import AdjustIcon from '@mui/icons-material/Adjust';

import imgf from "../img/imgf.jpg"
import map2 from "../img/ggg.png"


const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14,color:'#c7167a',}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14,color:'#c7167a'}}>
    career
    </Typography>,
  ];

export class About extends Component {
  render() {
    return (
      <div>
        <Appbar/>
      <br/>
      <br/>
      <Box sx={{backgroundImage:{xs:`url(${poo})`,sm:`url(${poo})`},width:'100%',display:'flex',height:{xs:200,sm:250,md:310},backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',}}>
      <br/>
      <br/>
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3,width:'100%'}}>
      <Container maxWidth='xl'>
      <Box sx={{background:'rgba(28,68,106,.7)',height:{xs:140,sm:180,md:200}, width:'100%',mt:1,}}>
      <Box sx={{mt:2}}>
      <br/>
       <Typography align='center' sx={{color:'#fff',fontWeight:'900',fontSize:{xs:17,sm:17,md:21},fontFamily:'inherit'}}>ABOUT THE REALESTAE</Typography>
       <Typography align='center' sx={{color:'#fff',fontWeight:'900',fontSize:{xs:17,sm:17,md:21},fontFamily:'inherit'}}>INDIA</Typography>
      </Box>


      <Box sx={{display:'flex',justifyContent:'center',mt:{xs:2,sm:2,md:5}}}>
      <Box sx={{backgroundColor:'#c7167a',height:40,width:150,borderRadius:10,}}>
<Box sx={{display:'flex',justifyContent:'center'}} >
<Box>
<Typography sx={{mt:1,color:'white'}}>Learn</Typography>
</Box>
</Box>
      </Box>
      </Box>
      </Box>
      </Container>
      </Box> 
 </Box>




 <Box sx={{minHeight:300,backgroundColor:'#e8eeee ',opacity:0.9,backgroundRepeat:''}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
  <Container maxWidth='xl'>
  <br/>
 <Box sx={{mt:1,mb:1,minHeight:300,}}>
 
 <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box sx={{display:'flex',flexDirection:'column'}}>
  <br/>
  <Typography variant='h1'  sx={{fontSize:{xs:22,sm:22,md:28},fontWeight:'800',fontFamily:'sans-serif'}}>We didn't find it for us,</Typography>
  <Typography variant='h1'  sx={{fontSize:{xs:22,sm:22,md:28},fontWeight:'800',mt:1,fontFamily:'sans-serif',color:'#c7167a '}}>so we created it for you</Typography>
  <br/>
  <Typography align='justify' sx={{mb:2,mr:{xs:'0',sm:'0',md:'10%',},fontWeight:'335',color:'black',fontSize:{xs:12,sm:12,md:13},lineHeight:2}}>Launched in 2019, REALESTATE INDIA, India’s No. 1 property portal, deals with every aspect of the consumers’ needs in the real estate industry. It is an online forum where buyers, sellers and brokers/agents can exchange information about real estate properties quickly, effectively and inexpensively. at our portal, you can advertise a property, search for a property, browse through properties, build your own property microsite, and keep yourself updated with the latest news and trends making headlines in the realty sector.
</Typography>
<Typography align='justify' sx={{mb:2,mr:{xs:'0',sm:'0',md:'10%',},fontWeight:'335',color:'black',fontSize:{xs:12,sm:12,md:13},lineHeight:2,mt:1}}>At present, REALESTATE INDIA prides itself for having around nine lakh property listings spanning across 600+ cities in India.
</Typography>
  </Box>
  
  </Grid>
  <Grid item xs={12} sm={12} md={6} lg={6}>

  <Box sx={{m:{xs:'0',sm:'0',md:'2'}}}>
  <Box sx={{borderRadius:10,height:370,}}>
  <Box
     component="img"
     sx={{
       height:370,
       display: 'block',
     objectFit:'cover',
       overflow: 'hidden',
       width:'100%',
       borderRadius:10
     }}
     src={imgf}
     alt=''
   />
  </Box>
  </Box>



    
  </Grid>
</Grid>
 </Box>
 <br/>
 </Container>
</Box>
 </Box>








 <Box sx={{backgroundImage: `url(${map2})`,minHeight:300,backgroundColor:'#022246 ',opacity:0.9}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
  <Container maxWidth='xl'>
  <br/>
  <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={6} lg={6}>
   <Box sx={{minHeight:250,}}>

   <Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>At present, REALESTATE INDIA prides itself for having around nine lakh property listings spanning across 600+ cities in India.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Of all, the website held over 5.7 lakh paid listings at the end of FY 2018-19. </Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>In addition to providing an online platform to real estate developers, brokers and property owners for listing their property for sale, purchase or rent, REALESTATE INDIA offers advertisement stints such as microsites, banners, home page links and project pages to the clients for better visibility and branding in the market.</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}> With the ever-evolving online search behaviour, REALESTATE INDIA shares updated information pertinent to real estate activities, assisting prospective buyers to make informed buying decision.</Typography>
</Box>
</Box>



   </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box sx={{minHeight:250,}}>

  <Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}> We make online property search easier, quicker and smarter!</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>REALESTATE INDIA won the award for ‘Personalized User Journey. Best Mobile Appies Award 2019 </Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>REALESTATE INDIA won the award for having the ‘Most Innovative Mobile App’ in the real estate category CMO ASIA Awards 2012  REALESTATE INDIA was awarded the ‘Most Admired Real Estate Website of the Year’ at the 3rd CMO Asia Awards for excellence in the real estate segment.</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>REALESTATE INDIA was announced the ‘Best Online Realty Portal’ by the Accommodation Times in 2018.</Typography>
</Box>
</Box>


  </Box>
  </Grid>
 </Grid>
 
 </Container>
 </Box>
 <br/>
  </Box>






 










      
      </div>
    )
  }
}

export default About








