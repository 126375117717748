import React, { Component } from "react";
import { AppBar, Box, Divider } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "./LandingPage";
import Appbar from "./Appbar";
import Properties from "./Properties";
import PropertyDescription from "./PropertyDescription";
////////// all router added here and routed and found .

import Media from '../footer_page/Media'
import Career from '../footer_page/Career'
import Faqs from '../footer_page/Faqs'
import Investor from '../footer_page/Investor'
import Community from '../footer_page/Community'
import Scholarship, { Schlorship } from '../footer_page/Schlorship'
import Privacy from '../footer_page/Privacy'
import Termcondition from '../footer_page/Termcondition'
import Covid from '../footer_page/Covid'
import Contactus from '../footer_page/Contactus'
import Aboutus from '../footer_page/About'

import Footer from './Footer'
import Login from "../Login";
import Register from "../Register";


const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  
  {
    path: "/Properties/:location",
    element: <Properties />,
  },

  {
    path: "/Properties/description",
    element: <PropertyDescription />,
  },
  ////// footerr details /////
  {
    path: "/login",
    element:<Login/>,
  },
  {
    path: "/register",
    element:<Register/>,
  },

  
  {
    path: "/about",
    element:<Aboutus/>,
  },
  {
    path: "/media",
    element:<Media/>,
  },
  {
    path: "/career",
    element:<Career/>,
  },
  {
    path: "/faq",
    element:<Faqs/>,
  },
  {
    path: "/investors",
    element:<Investor/>,
  },
  {
    path: "/community",
    element:<Community/>,
  },
  {
    path: "/scholarship",
    element:<Schlorship/>,
  },


  {
    path: "/privacy_policy",
    element:<Privacy/>,
  },
  {
    path: "/terms",
    element:<Termcondition/>,
  },
  {
    path: "/covid",
    element:<Covid/>,
  },
 
  {
    path: "/contactus",
    element:<Contactus/>,
  },



]);

export class Main extends Component {
  render() {
    return (
      <div>

        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
        <Divider/>
        <Footer/>
      </div>
    );
  }
}

export default Main;
