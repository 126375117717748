import React, { Component } from "react";
import {
  Box,
  Card,
  Container,
  Divider,
  Grid2,
  TextField,
  MenuItem,
  Button,
  Skeleton,
  Avatar,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import Autocomplete from "react-google-autocomplete";
import Menu from "@mui/material/Menu";
import Paper from "@mui/material/Paper";
import poo from "../assets/bgimg.jpg";
import Scroll from "../Views/Scroll";
import MicIcon from "@mui/icons-material/Mic";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import rr from "../img/one.jpeg";
import ccc from "../img/new_home.jpg";
import d from "../img/d.webp";
import l from "../img/l.webp";
import HomeIcon from "@mui/icons-material/Home";
import property from "../img/property.webp";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import img from "../assets/imgtwo.webp";
import one from "../img/1.jpg";
import two from "../img/2.jpg";
import three from "../img/3.jpg";
import four from "../img/4.webp";
import five from "../img/5.webp";
import six from "../img/6.webp";

import CheckIcon from "@mui/icons-material/Check";
import socity_img from '../socity_img/socity.jpg'
import CallIcon from '@mui/icons-material/Call';
import st from '../plot_img/st.jpg'
import CloseIcon from '@mui/icons-material/Close';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import AddHomeIcon from '@mui/icons-material/AddHome';
import VerifiedIcon from '@mui/icons-material/Verified';
import LandslideIcon from '@mui/icons-material/Landslide';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import Appbar from "./Appbar";
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import pg from '../img/pg.webp'


const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: grey[100],
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.background.default,
  }),
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.applyStyles('dark', {
    backgroundColor: grey[800],
  }),
}));


///AIzaSyDg2LA8IAl3SPgxqiAt1FBNb54Z8jk4kZ0
const api_key = "AIzaSyDg2LA8IAl3SPgxqiAt1FBNb54Z8jk4kZ0";
export class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "buy",
      anchorEl: null,
      open: false,
      display: false,

      //////////////// state data for location search field
      location_name: "",
    };
    this.setAnchorEl = this.setAnchorEl.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.child = React.createRef();
  }

  handleClick(event) {
    this.setAnchorEl(event.currentTarget);
  }
  setAnchorEl(value) {
    this.setState({
      anchorEl: value,
      open: !this.state.open,
    });
  }
  handleClose() {
    this.setAnchorEl(null);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.mmm);
  }







  toggleDrawer = (newOpen) => () => {
    this.setState({
      open: true
    })
  };

  toggleDrawerr = (newOpen) => () => {
    this.setState({
      open: false
    })
  };

  render() {
    return (
      <div>
               <Appbar />
        <Box>
          {
            ///// desktop view here and solution
          }

          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <Box
              sx={{
                display: { xs: "none", sm: "none" ,md:'block'},
                height: 340,
                background: "#00337b",
                backgroundImage: `url(${poo})`,
                width: "100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
              }}
            >
              <Container maxWidth="xl">
                <br />
                <br />
              </Container>
            </Box>

            <Box sx={{ minHeight: 750, background: "#ffffff" }}>
              <Container maxWidth="xl">
                <br />
                <br />
                <Card
                  sx={{
                    display: { xs: "none", sm: "block" },
                    position: "relative",
                    height: 130,
                    marginLeft: { sm: "1%", md: "10%" },
                    marginRight: { sm: "1%", md: "10%" },
                    backgroundColor: "#fff",
                    marginTop: -14,
                  }}
                >
                  <Box sx={{ width: "100%", mt: 2, mb: 2 }}>
                    <Grid2 container spacing={2}>
                      <Grid2 item size={{ xs: 2 }}>
                        <Typography
                          textAlign="center"
                          onClick={() => this.setState({ value: "buy" })}
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                            color: this.state.value == "buy" ? "#00337b" : "",
                          }}
                        >
                          Buy Land/Plot
                        </Typography>
                      </Grid2>
                      <Grid2 item size={{ xs: 2 }}>
                        <Typography
                          textAlign="center"
                          onClick={() => this.setState({ value: "rent" })}
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                            color: this.state.value == "rent" ? "#00337b" : "",
                          }}
                        >
                          Buy Properties
                        </Typography>
                      </Grid2>
                      <Grid2 item size={{ xs: 2 }}>
                        <Typography
                          textAlign="center"
                          onClick={() => this.setState({ value: "pg" })}
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                            color: this.state.value == "pg" ? "#00337b" : "",
                          }}
                        >
                          PG/Co-Living
                        </Typography>
                      </Grid2>
                      <Grid2 item size={{ xs: 2 }}>
                        <Typography
                          textAlign="center"
                          onClick={() => this.setState({ value: "com" })}
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                            color: this.state.value == "com" ? "#00337b" : "",
                          }}
                        >
                          Buy Comercial
                        </Typography>
                      </Grid2>
                      <Grid2 item size={{ xs: 2 }}>
                        <Typography
                          textAlign="center"
                          onClick={() => this.setState({ value: "cow" })}
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                            color: this.state.value == "cow" ? "#00337b" : "",
                          }}
                        >
                          Rental Properties
                        </Typography>
                      </Grid2>
                      <Grid2 item size={{ xs: 2 }}>
                        <Typography
                          textAlign="center"
                          onClick={() => this.setState({ value: "land" })}
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                            color: this.state.value == "land" ? "#00337b" : "",
                          }}
                        >
                          Legal Help
                        </Typography>
                      </Grid2>
                    </Grid2>
                  </Box>

                  <Divider />

                  <Box sx={{ height: 70 }}>
                    <Grid2 container>
                      <Grid2 item size={{ xs: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItem: "center",
                              width: "100%",
                              marginLeft: "10%",
                              marginRight: "10%",
                              justifyContent: "center",
                            }}
                          >
                            <TextField
                              id="outlined-select-currency"
                              select
                              fullWidth
                              value="MUMBAI"
                              variant="standard"
                              sx={{ width: "70%", height: 70, marginTop: 2.5 }}
                              InputProps={{
                                disableUnderline: "true",
                              }}
                            >
                              {currencies.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                  sx={{fontSize:13}}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        </Box>
                      </Grid2>
                      <Grid2 item>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ height: "100%", marginLeft: 1, marginRight: 1 }}
                        />
                      </Grid2>

                      <Grid2 item size={{ xs: 9 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            marginTop: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItem: "center",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                marginLeft: 5,
                                marginRight: 1,
                              }}
                            >
                              <Autocomplete
                                apiKey={api_key}
                                onPlaceSelected={(place) =>
                                  this.setState({
                                    location_name: place
                                      ? place.formatted_address
                                      : "",
                                  })
                                }
                                style={{
                                  borderRadius: 5,
                                  border: "none",
                                  borderColor: "white",
                                  outline: "none",
                                  width: "100%",
                                }}
                              />

                              <Box
                                sx={{
                                  display: "flex",
                                  borderRadius: 8,
                                  backgroundColor: "#f0f9ff",
                                  justifyContent: "center",
                                  mr: 2,
                                }}
                              >
                                <MyLocationIcon
                                  sx={{
                                    height: 20,
                                    width: 20,
                                    m: 1,
                                    color: "#00337b",
                                  }}
                                />
                              </Box>

                              <Box
                                onClick={this.speech}
                                sx={{
                                  display: "flex",
                                  borderRadius: 8,
                                  backgroundColor: "#f0f9ff",
                                  justifyContent: "center",
                                  ml: 2,
                                  marginRight: 2,
                                }}
                              >
                                <MicIcon
                                  sx={{
                                    height: 20,
                                    width: 20,
                                    m: 1,
                                    color: "#00337b",
                                  }}
                                />
                              </Box>

                              <Button
                                variant="contained"
                                onClick={() =>
                               
                                    window.open('/Properties/:location')
                                  
                                }
                                size="small"
                                sx={{ textTransform: "none", marginLeft: 2 }}
                                disableElevation
                              >
                                Search
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Grid2>
                    </Grid2>
                  </Box>
                </Card>
                <Box>
                  <Grid2 container spacing={1}>
                    <Grid2 item size={{ xs: 12, sm: 12, md: 9 }}>
                      <br />
                      <br />
                      <Typography
                        variant="h2"
                        sx={{
                          fontSize: 23,
                          fontWeight: "600",
                          color: "#0a1d3e",
                        }}
                      >
                        Recommended Properties
                      </Typography>
                      <Scroll />
                      <br />

                      <Box 
                      
                      >
                        <Typography
                          variant="h2"
                          sx={{
                            fontSize: 23,
                            fontWeight: "550",
                            color: "#0a1d3e",
                          }}
                        >
                          Localities you may like
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{
                            fontSize: 12,
                            fontWeight: "500",
                            color: "#0a1d3e",
                          }}
                        >
                          based on the localities you have explored in Pune
                          South
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            overflowX: "scroll",
                            overflowY: "hidden",
                            mt: 2,
                            mb: 1,
                            whiteSpace: "nowrap",
                            scrollbarWidth: "none",
                          }}
                        >
                          {[1, 2, 3, 4, 5, 6, 7].map(() => (
                            <Box onClick={()=>window.open('/Properties/:location')} sx={{ height: 120, width: 260, mr: 1, mb: 1 }}>
                              <Card
                                elevation={1}
                                sx={{
                                  height: 120,
                                  width: 260,
                                  borderRadius: 2,
                                  mr: 1,
                                  border: 1,
                                  borderColor: "#e9edee",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: 70,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    pl: 2,
                                    pr: 2,
                                  }}
                                >
                                  <Box sx={{ display: "flex" }}>
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={rr}
                                      sx={{ width: 47, height: 47 }}
                                    />
                                  </Box>

                                  <Box sx={{ ml: 1 }}>
                                    <Typography
                                      sx={{
                                        fontWeight: "600",
                                        fontSize: 13,
                                        color: "#233144",
                                      }}
                                    >
                                      Apartment
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: 11,
                                        color: "#233144",
                                      }}
                                    >
                                      ₹ 4,500/sq.ft
                                    </Typography>
                                  </Box>
                                </Box>
                                <Divider />

                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "50%",
                                      height: 50,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: "600",
                                        color: "#1d75d2",
                                      }}
                                    >
                                      Insight
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      height: 35,
                                      width: 2,
                                      backgroundColor: "#e9edee",
                                    }}
                                  ></Box>
                                  <Box
                                    sx={{
                                      width: "50%",
                                      height: 50,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: "600",
                                        color: "#1d75d2",
                                      }}
                                    >
                                      Properties
                                    </Typography>
                                  </Box>
                                </Box>
                              </Card>
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      <br />

                      <Box>
                        <Typography
                          variant="h2"
                          sx={{
                            fontSize: 23,
                            fontWeight: "550",
                            color: "#0a1d3e",
                          }}
                        >
                          Apartments, Villas and more
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{
                            fontSize: 12,
                            fontWeight: "500",
                            color: "#0a1d3e",
                          }}
                        >
                          in south pune
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            overflowX: "scroll",
                            mt: 2,
                            mb: 2,
                            scrollbarWidth: "none",
                          }}
                        >
                          {[
                            { color: "#fef5e4", img: d ,first_name : "Residencial", last_name : "Villa"},
                            { color: "#f0f9ff", img: l ,first_name : "Residencial", last_name : "Land"},
                            { color: "#d7f2e3", img: property ,first_name : "Independent", last_name : "House"},
                            { color: "#fef5e4", img:four ,first_name : "Residencial", last_name : "Flats"},
                            { color: "#f0f9ff", img: five ,first_name :"Commercial", last_name : "Apartment" },
                          ].map((i) => (
                            <Box  onClick={()=>window.open('/Properties/:location')} sx={{ height: 330, width: 250, mr: 1 }}>
                              <Card
                                elevation={1}
                                sx={{
                                  height: 330,
                                  width: 250,
                                  borderRadius: 2,
                                  backgroundColor: i.color,
                                  backgroundImage: `url(${i.img})`,
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: 24,
                                    fontWeight: "800",
                                    textAlign: "center",
                                    mt: 5,
                                    color: "#445368",
                                  }}
                                >
                                  {i.first_name}
                                  <br />
                                  {i.last_name}
                                </Typography>
                              </Card>
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      <br />

                      <Box sx={{ backgroundColor: "#fbfefe" }}>
                        <Typography
                          variant="h2"
                          sx={{
                            fontSize: 20,
                            fontWeight: "550",
                            color: "#0a1d3e",
                            ml: 2,
                            pt: 2,
                          }}
                        >
                          Flats In Best Socity
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            overflowX:'scroll',
                            scrollbarWidth: "none",
                            p: 2,
                          }}
                        >
                          {["#fef5e4", "#f0f9ff", "#f0f9ff", "#f0f9ff", "#f0f9ff"].map((i) => (
                            <Box  onClick={()=>window.open('/Properties/:location')} sx={{ height: 350,width: 270,mr:1}}>
                            <Paper
                              elevation={0}
                              sx={{
                                height: 350,
                                width: 270,
                                backgroundColor:'#071435',
                                borderRadius: 2,
                                mr: 1,
                              }}
                            >
                             <Box
                                elevation={1}
                                sx={{
                                  height: 170,
                                  width: '100%',
                                  borderRadius: 2,
                                  backgroundColor: "#fff",
                                  backgroundImage:  `url(${socity_img})`,
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                }}
                              >
                              </Box>
                                <Typography sx={{fontSize:17,mt:1,ml:1,fontWeight:'bold',color:'#fff'}}>Roopan socity</Typography>
                                <Typography sx={{fontSize:13,mt:1,ml:1,fontWeight:'400',color:'#fff'}}>Rajiv Nagar South, Mhada Colony, Viman Nagar, Pune, Maharashtra - 411014</Typography>
                                <Typography sx={{fontSize:15,fontWeight:'bold',color:'#fff',ml:1,mt:1}}>₹ 4L - 8L</Typography>

                                <Box sx={{mt:1}}>
                                  <Button size="small" sx={{fontSize:11,ml:1,backgroundColor:'#ab266c'}} startIcon={<CallIcon sx={{height:11,width:11}}/>} variant="contained">Contact</Button>
                                </Box>

                            </Paper>
                            </Box>
                          ))}
                        </Box>
                        <br />
                      </Box>

                  <br/>

                  <Typography sx={{fontSize:22,fontWeight:'bold',ml:1}}>Favourate Land</Typography>
                  <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            maxWidth:'100%',
                            overflowX: "scroll",
                            mt:0.5,
                            mb: 2,
                            scrollbarWidth: "none",
                          }}
                        >
          {
            [1,2,3,5,6,7].map((e)=>(

         <Box sx={{minHeight:250,m:1,width:260}}  onClick={()=>window.open('/Properties/:location')}>
     <Paper elevation={1} sx={{minHeight:310,width:260 }}>
     <Box sx={{height:150,width:'100%',borderRadius:2,backgroundImage: `url(${st})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
     
     <Box sx={{height:50,width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
           <Box sx={{height:15,width:50,backgroundColor:'#04afaf',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <CheckIcon sx={{height:13,width:13,color:'#fff'}}/> <Typography  sx={{color:'#fff',fontSize:9,ml:0.5,mt:0.5,fontWeight:'600'}}>RERA</Typography>
      </Box>
            <FavoriteBorderIcon sx={{height:25,width:25,color:'#fff',mr:1}}/>
     </Box>
  
     </Box>
 
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1,ml:1}}>
       Commericial land near airport
     </Typography>
     <Typography  sx={{mt:0.8,color:'grey',fontWeight:'500',fontSize:12,textAlign:'left',ml:1}}>
       Rajiv Nagar South, Mhada Colony, Viman Nagar, Pune, Maharashtra - 411014
     </Typography>
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1,ml:1}}>
       30L / 1000sqft
     </Typography>

     <Button variant="contained" disableElevation size="small" startIcon={<CallIcon/>} sx={{ml:1,mt:1,mb:1,fontSize:11}}>View Number</Button>
   </Paper>
         </Box>
            ))
          }
         
                  



                   </Box>

<br/>


<Typography sx={{fontSize:22,fontWeight:'bold',ml:1}}>Rental PG For buys/girl</Typography>
                  <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            maxWidth:'100%',
                            overflowX: "scroll",
                            mt:0.5,
                            mb: 2,
                            scrollbarWidth: "none",
                          }}
                        >
          {
            [1,2,3,5,6,7].map((e)=>(

         <Box sx={{minHeight:250,m:1,width:260}}  onClick={()=>window.open('/Properties/:location')}>
     <Paper elevation={1} sx={{minHeight:310,width:260 }}>
     <Box sx={{height:150,width:'100%',borderRadius:2,backgroundImage: `url(${pg})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
     
     <Box sx={{height:50,width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
           <Box sx={{height:15,width:50,backgroundColor:'#04afaf',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <CheckIcon sx={{height:13,width:13,color:'#fff'}}/> <Typography  sx={{color:'#fff',fontSize:9,ml:0.5,mt:0.5,fontWeight:'600'}}>RERA</Typography>
      </Box>
            <FavoriteBorderIcon sx={{height:25,width:25,color:'#fff',mr:1}}/>
     </Box>
  
     </Box>
 
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1,ml:1}}>
     PG near univertcity- pune
     </Typography>
     <Typography  sx={{mt:0.8,color:'grey',fontWeight:'500',fontSize:12,textAlign:'left',ml:1}}>
       Rajiv Nagar South, Mhada Colony, Viman Nagar, Pune, Maharashtra - 411014
     </Typography>
     <br/>
     <Button variant="contained" disableElevation size="small" startIcon={<CallIcon/>} sx={{ml:1,mt:1,mb:1,fontSize:11,backgroundColor:'#ab266c'}}>View Number</Button>
   </Paper>
         </Box>
            ))
          }
         
                  



                   </Box>












                  
                      <br />
                    </Grid2>

                    <Grid2 item size={{ xs: 12, sm: 12, md: 3 }}>
                      <Paper sx={{ minHeight: 300, width: "100%", mt: 6, position:'sticky', }}>
                        <br />
                        <Grid2 container spacing={2}>
                          <Grid2 item size={{ xs: 6 }}>
                            <Box
                              sx={{
                                backgroundColor: "#fff0e6",
                                height: 120,
                                marginLeft: 1,
                                borderRadius: 3,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  padding: 2,
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: 30, fontWeight: 15 }}
                                >
                                  3
                                </Typography>
                                <ArrowOutwardIcon
                                  fontSize="small"
                                  sx={{ color: "#fe9f43" }}
                                />
                              </Box>

                              <Typography
                                sx={{
                                  paddingLeft: 2,
                                  fontSize: 13,
                                  color: "#42526e",
                                  fontWeight: "700",
                                }}
                              >
                                Shortlisted
                              </Typography>
                            </Box>
                          </Grid2>
                          <Grid2 item size={{ xs: 6 }}>
                            <Box
                              sx={{
                                backgroundColor: "#f0f9ff",
                                height: 120,
                                mr: 1,
                                borderRadius: 3,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  padding: 2,
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: 30, fontWeight: 15 }}
                                >
                                  7
                                </Typography>
                                <ArrowOutwardIcon
                                  fontSize="small"
                                  sx={{ color: "#1b7bce" }}
                                />
                              </Box>

                              <Typography
                                sx={{
                                  paddingLeft: 2,
                                  fontSize: 13,
                                  color: "#42526e",
                                  fontWeight: "700",
                                }}
                              >
                                Viewed
                              </Typography>
                            </Box>
                          </Grid2>
                        </Grid2>
                      </Paper>
                    </Grid2>
                  </Grid2>
                </Box>
              </Container>
            </Box>
          </Box>

          {
            ///// mobile view and list display here and founded ??
          }

          <Box sx={{ display: { xs: "block", sm: "block",md:'none' } }}>
            <Container maxWidth="xl">
              <Box
                sx={{
                  display: { xs: "flex", sm: "none" },
                  flexDirection: "row",
                  mt: 7,
                  mb: 2,
                  overflowX: "scroll",
                  minHeight: 50,
                  scrollbarWidth: "none",
                }}
              >
                <Paper
                 onClick={()=>window.open('/Properties/:location')}
                  elevation={1}
                  sx={{
                    minWidth: 110,
                    height: 47,
                    borderRadius: 8,
                    ml: 1,
                    mr: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height:37,
                      width: 37,
                      borderRadius: 8,
                      backgroundColor: "#fafcff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddHomeIcon
                      sx={{ height: 23, width: 23, color: "#2c3089" }}
                    />
                  </Box>{" "}
                  <Typography
                    sx={{ fontSize: 17, fontWeight: "600", mt: 0.5, ml: 0.3 }}
                  >
                    Buy
                  </Typography>
                </Paper>

                <Paper
                  elevation={1}
                  sx={{
                    minWidth: 110,
                    height: 47,
                    borderRadius: 8,
                    ml: 1,
                    mr: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height:37,
                      width: 37,
                      borderRadius: 8,
                      backgroundColor: "#fafcff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <HomeIcon
                      sx={{ height: 23, width: 23, color: "#2c3089" }}
                    />
                  </Box>{" "}
                  <Typography
                    sx={{ fontSize: 17, fontWeight: "600", mt: 0.5, ml: 0.3 }}
                  >
                    Rent
                  </Typography>
                </Paper>

                <Paper
                  elevation={1}
                  sx={{
                    minWidth: 190,
                    height: 47,
                    borderRadius: 8,
                    ml: 1,
                    mr: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height:37,
                      width: 37,
                      borderRadius: 8,
                      backgroundColor: "#fafcff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >  
                    <VerifiedIcon
                      sx={{ height: 23, width: 23, color: "#f55904" }}
                    />
                  </Box>{" "}
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "600", mt: 0.5, ml:0.5,mr:0.5 }}
                  >
                    NEW_LAUNCH
                  </Typography>
                </Paper>

                <Paper
                  elevation={1}
                  sx={{
                    minWidth: 160,
                    height: 47,
                    borderRadius: 8,
                    ml: 1,
                    mr: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height:37,
                      width: 37,
                      borderRadius: 8,
                      backgroundColor: "#fafcff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LandslideIcon
                      sx={{ height: 23, width: 23, color: "#2c3089" }}
                    />
                  </Box>{" "} 
                  <Typography
                    sx={{ fontSize: 17, fontWeight: "600", mt: 0.5, ml: 0.3 }}
                  >
                    Plot/Land
                  </Typography>
                </Paper>

                <Paper
                  elevation={1}
                  sx={{
                    minWidth: 230,
                    height: 47,
                    borderRadius: 8,
                    ml: 1,
                    mr: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height:37,
                      width: 37,
                      borderRadius: 8,
                      backgroundColor: "#fafcff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddBusinessOutlinedIcon
                      sx={{ height: 23, width: 23, color: "#2c3089" }}
                    />
                  </Box>{" "}
                  <Typography
                    sx={{ fontSize: 17, fontWeight: "600", mt: 0.5, ml: 0.3 }}
                  >
                    Buy Commericial
                  </Typography>
                </Paper>
                
                <Paper
                  elevation={1}
                  sx={{
                    minWidth: 190,
                    height: 47,
                    borderRadius: 8,
                    ml: 1,
                    mr: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height:37,
                      width: 37,
                      borderRadius: 8,
                      backgroundColor: "#fafcff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SingleBedIcon
                      sx={{ height: 23, width: 23, color: "#2c3089" }}
                    />
                  </Box>{" "} 
                  <Typography
                    sx={{ fontSize: 17, fontWeight: "600", mt: 0.5, ml: 0.3 }}
                  >
                  PG/Co-Living
                  </Typography>
                </Paper>





              </Box>

              <Box
                sx={{
                  height: 170,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundImage: `url(${rr})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: 1,
                }}
              >
                <Paper
                  elevation={2}
                 // variant="outlined"
                 onClick={()=>this.setState({open:true})}
                  sx={{
                    width: "95%",
                    height: 47,
                    borderRadius: 2,
                    border: 0.04,
                    borderColor: "#33abdf",
                    mt: 18,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      ml: 1,
                      height: 47,
                      width: "100%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <SearchIcon sx={{ color: "grey", mr: 1 }} />
                    <Typography textAlign="left" sx={{ fontSize: 14 }}>
                      Search : Name Of Place
                    </Typography>
                  </Box>

                  <MicIcon sx={{ mr: 1, color: "#33abdf" }} />
                </Paper>

               

              </Box>
              <br />
              <Box sx={{ minHeight: 200, mt: 2 }}>
                <Typography sx={{ fontSize: 19, fontWeight: "600" ,fontFamily:`"Roboto", "Helvetica", "Arial", sans-serif`,mb:1}}>
                  Recommend Properties
                </Typography>

                <Grid2 container spacing={1}>
                  <Grid2 item size={{ xs: 6 }}>
                    <Paper  onClick={()=>window.open('/Properties/:location')} elevation={0} sx={{ width: "100%", height: 220 }}>
                      <Box
                        sx={{
                          height: 150,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          backgroundImage: `url(${ccc})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: 1,
                        }}
                      >
                        <Box
                          sx={{
                            height: 50,
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignItems: "center",
                          }}
                        >
                          <FavoriteBorderIcon
                            sx={{
                              height: 30,
                              width: 30,
                              color: "#fff",
                              backgroundColor: "rgba(2, 7, 13,0.3)",
                              mr: 1,
                            }}
                          />
                        </Box>
                        <Box
                          elevation={0}
                          sx={{
                            height: 30,
                            width: 60,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            ml: 1,
                            mt: 7,
                            borderRadius: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: 14, fontWeight: "600" }}>
                            ₹ 40 L
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{ fontSize: 12, color: "black", mt: 0.5 }}
                        >
                          2 BHK independent home
                        </Typography>
                        <Typography sx={{ fontSize: 11, color: "gray" }}>
                          Intalegai redgion bed
                        </Typography>
                        <Typography sx={{ fontSize: 11, color: "gray" }}>
                          Posted by owner 4 month ago
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid2>
                  <Grid2 item size={{ xs: 6 }}>
                    <Paper  onClick={()=>window.open('/Properties/:location')} elevation={0} sx={{ width: "100%", height: 220 }}>
                      <Box
                        sx={{
                          height: 150,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          backgroundImage: `url(${ccc})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: 1,
                        }}
                      >
                        <Box
                          sx={{
                            height: 50,
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignItems: "center",
                          }}
                        >
                          <FavoriteBorderIcon
                            sx={{
                              height: 30,
                              width: 30,
                              color: "#fff",
                              backgroundColor: "rgba(2, 7, 13,0.3)",
                              mr: 1,
                            }}
                          />
                        </Box>
                        <Box
                          elevation={0}
                          sx={{
                            height: 30,
                            width: 60,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            ml: 1,
                            mt: 7,
                            borderRadius: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: 14, fontWeight: "600" }}>
                            ₹ 40 L
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{ fontSize: 12, color: "black", mt: 0.5 }}
                        >
                          2 BHK independent home
                        </Typography>
                        <Typography sx={{ fontSize: 11, color: "gray" }}>
                          Intalegai redgion bed
                        </Typography>
                        <Typography sx={{ fontSize: 11, color: "gray" }}>
                          Posted by owner 4 month ago
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid2>
                  <Grid2 item size={{ xs: 6 }}>
                    <Paper  onClick={()=>window.open('/Properties/:location')} elevation={0} sx={{ width: "100%", height: 220 }}>
                      <Box
                        sx={{
                          height: 150,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          backgroundImage: `url(${ccc})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: 1,
                        }}
                      >
                        <Box
                          sx={{
                            height: 50,
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignItems: "center",
                          }}
                        >
                          <FavoriteBorderIcon
                            sx={{
                              height: 30,
                              width: 30,
                              color: "#fff",
                              backgroundColor: "rgba(2, 7, 13,0.3)",
                              mr: 1,
                            }}
                          />
                        </Box>
                        <Box
                          elevation={0}
                          sx={{
                            height: 30,
                            width: 60,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            ml: 1,
                            mt: 7,
                            borderRadius: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: 14, fontWeight: "600" }}>
                            ₹ 40 L
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{ fontSize: 12, color: "black", mt: 0.5 }}
                        >
                          2 BHK independent home
                        </Typography>
                        <Typography sx={{ fontSize: 11, color: "gray" }}>
                          Intalegai redgion bed
                        </Typography>
                        <Typography sx={{ fontSize: 11, color: "gray" }}>
                          Posted by owner 4 month ago
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid2>
                  <Grid2 item size={{ xs: 6 }}>
                    <Paper  onClick={()=>window.open('/Properties/:location')} elevation={0} sx={{ width: "100%", height: 220 }}>
                      <Box
                        sx={{
                          height: 150,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          backgroundImage: `url(${ccc})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: 1,
                        }}
                      >
                        <Box
                          sx={{
                            height: 50,
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignItems: "center",
                          }}
                        >
                          <FavoriteBorderIcon
                            sx={{
                              height: 30,
                              width: 30,
                              color: "#fff",
                              backgroundColor: "rgba(2, 7, 13,0.3)",
                              mr: 1,
                            }}
                          />
                        </Box>
                        <Box
                          elevation={0}
                          sx={{
                            height: 30,
                            width: 60,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            ml: 1,
                            mt: 7,
                            borderRadius: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: 14, fontWeight: "600" }}>
                            ₹ 40 L
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{ fontSize: 12, color: "black", mt: 0.5 }}
                        >
                          2 BHK independent home
                        </Typography>
                        <Typography sx={{ fontSize: 11, color: "gray" }}>
                          Intalegai redgion bed
                        </Typography>
                        <Typography sx={{ fontSize: 11, color: "gray" }}>
                          Posted by owner 4 month ago
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid2>
                </Grid2>
              </Box>

              <br />
              <Box sx={{ maxWidth: "100%" }}>
                <Typography
                  variant="h2"
                  sx={{ fontSize: 18, fontWeight: "550",fontFamily:`"Roboto", "Helvetica", "Arial", sans-serif`,mb:1}}>
                  Land Types
                </Typography>
                <Typography
                  variant="h2"
                  sx={{ fontSize: 12, fontWeight: "500", color: "#0a1d3e" }}
                >
                  in south pune
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    mt: 2,
                    mb: 2,
                    overflowX: "auto",
                    minHeight: 200,
                    whiteSpace: "nowrap",
                    scrollbarWidth: "none",
                  }}
                >
                  {[
                    { color: "#fef5e4", img: d },
                    { color: "#f0f9ff", img: l },
                    { color: "#d7f2e3", img: property },
                  ].map((i) => (
                    <Box  onClick={()=>window.open('/Properties/:location')} sx={{ height: 250, width: 220, mr: 1 }}>
                      <Box
                        elevation={0}
                        sx={{
                          height: 250,
                          width: 220,
                          borderRadius: 2,
                          backgroundColor: i.color,
                          backgroundImage: `url(${i.img})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 21,
                            fontWeight: "800",
                            textAlign: "center",
                            pt: 3,
                            color: "#445368",
                          }}
                        >
                          Residencial
                          <br />
                          Land
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>

              <br />
              <Box sx={{ maxWidth: "100%" }}>
                <Typography
                  variant="h2"
                  sx={{ fontSize: 18, fontWeight: "550",fontFamily:`"Roboto", "Helvetica", "Arial", sans-serif`,mb:1}}>
                  Apartments, Villas and more
                </Typography>
                <Typography
                  variant="h2"
                  sx={{ fontSize: 12, fontWeight: "500", color: "#0a1d3e" }}
                >
                  in south pune
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 2,
                    mb: 2,
                    overflowX: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  {[1, 2, 3, 4, 5, 6].map(() => (
                    <Box  onClick={()=>window.open('/Properties/:location')}
                      sx={{
                        height: 120,
                        width: 240,
                        borderRadius: 2,
                        mb: 1,
                        mr: 1,
                      }}
                    >
                      <Card
                        elevation={1}
                        sx={{
                          height: 120,
                          width: 240,
                          borderRadius: 2,
                          border: 1,
                          borderColor: "#e9edee",
                        }}
                      >
                        <Box
                          sx={{
                            height: 70,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            pl: 2,
                            pr: 2,
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Avatar
                              alt="Remy Sharp"
                              src={rr}
                              sx={{ width: 47, height: 47 }}
                            />
                          </Box>

                          <Box sx={{ ml: 1 }}>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: 13,
                                color: "#233144",
                              }}
                            >
                              Apartment
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "500",
                                fontSize: 11,
                                color: "#233144",
                              }}
                            >
                              ₹ 4,500/sq.ft
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "50%",
                              height: 50,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 13,
                                fontWeight: "600",
                                color: "#1d75d2",
                              }}
                            >
                              Insight
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              height: 35,
                              width: 2,
                              backgroundColor: "#e9edee",
                            }}
                          ></Box>
                          <Box
                            sx={{
                              width: "50%",
                              height: 50,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 13,
                                fontWeight: "600",
                                color: "#1d75d2",
                              }}
                            >
                              Properties
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  ))}
                </Box>
              </Box>

              <br />

              <Box sx={{ maxWidth: "100%" }}>
                <Typography
                  variant="h2"
                  sx={{ fontSize: 18, fontWeight: "550",fontFamily:`"Roboto", "Helvetica", "Arial", sans-serif`,mb:1}}>
                
                  Apartments, Villas and more
                </Typography>
                <Typography
                  variant="h2"
                  sx={{ fontSize: 12, fontWeight: "500", color: "#0a1d3e" }}
                >
                  in south pune
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 2,
                    mb: 2,
                    overflowX: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  {[1, 2, 3, 4, 5, 6].map(() => (
                    <Box  onClick={()=>window.open('/Properties/:location')} sx={{ height: 250, width: 250, mr: 1 }}>
                      <Paper elevation={0} sx={{ height: 250, width: 250 }}>
                        <Box
                          sx={{
                            height: 150,
                            width: "100%",
                            borderRadius: 2,
                            backgroundImage: `url(${one})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        >
                          <Box
                            sx={{
                              height: 50,
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                height: 15,
                                width: 50,
                                backgroundColor: "#04afaf",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CheckIcon
                                sx={{ height: 13, width: 13, color: "#fff" }}
                              />{" "}
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: 9,
                                  ml: 0.5,
                                  mt: 0.5,
                                  fontWeight: "600",
                                }}
                              >
                                RERA
                              </Typography>
                            </Box>
                            <FavoriteBorderIcon
                              sx={{
                                height: 25,
                                width: 25,
                                color: "#fff",
                                mr: 1,
                              }}
                            />
                          </Box>
                        </Box>

                        <Typography
                          sx={{
                            fontSize: 15,
                            fontWeight: "600",
                            color: "#0f1f42",
                            textAlign: "left",
                            mt: 1,
                          }}
                        >
                          PG IN VIMAN NAGAR - PUNE
                        </Typography>
                        <Typography
                          sx={{
                            mt: 0.8,
                            color: "grey",
                            fontWeight: "500",
                            fontSize: 12,
                            textAlign: "left",
                          }}
                        >
                          Rajiv Nagar South, Mhada Colony, Viman Nagar, Pune,
                          Maharashtra - 411014
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 15,
                            fontWeight: "600",
                            color: "#0f1f42",
                            textAlign: "left",
                            mt: 1,
                          }}
                        >
                          ₹ 4.8L - 8L
                        </Typography>
                      </Paper>
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box sx={{ maxWidth: "100%" }}>
                <Typography
                  variant="h2"
                  sx={{ fontSize: 18, fontWeight: "550",fontFamily:`"Roboto", "Helvetica", "Arial", sans-serif`,mb:1}}>
                  Apartments, Villas and more
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 2,
                    mb: 2,
                    overflowX: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  {[1, 2, 3, 4, 5, 6].map(() => (
                    <Box  onClick={()=>window.open('/Properties/:location')} sx={{ height: 150, width: 270, mr: 1 }}>
                      <Paper elevation={1} sx={{ height: 150, width: 270 }}>
                      <Box sx={{height:150,width:'100%',borderRadius:2,backgroundImage: `url(${two})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
     
                      <Box sx={{height:50,width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                       <Box sx={{height:15,width:50,backgroundColor:'#04afaf',display:'flex',justifyContent:'center',alignItems:'center'}}>
                         <CheckIcon sx={{height:13,width:13,color:'#fff'}}/> <Typography  sx={{color:'#fff',fontSize:9,ml:0.5,mt:0.5,fontWeight:'600'}}>RERA</Typography>
                       </Box>
                      <FavoriteBorderIcon sx={{height:25,width:25,color:'#fff',mr:1}}/>
                        </Box>
  
                        </Box>
                      </Paper>
                    </Box>
                  ))}
                </Box>
              </Box>
              

             <Typography sx={{ fontSize: 18, fontWeight: "550",fontFamily:`"Roboto", "Helvetica", "Arial", sans-serif`,mb:1}}>Favourate Land</Typography>
                  <Box
                            sx={{
                              display: "flex",
                              maxWidth:'100%',
                              flexDirection: "row",
                              mt: 1,
                              mb: 2, 
                              overflowX: "scroll",
                              scrollbarWidth: "none",
                            }}
                        >
          {
            [1,2,3,4].map((e)=>(
      <Box  onClick={()=>window.open('/Properties/:location')} sx={{minHeight:250,m:1,width:260}}>
     <Paper elevation={1} sx={{minHeight:310,width:260 }}>
     <Box sx={{height:150,width:'100%',borderRadius:2,backgroundImage: `url(${st})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
     
     <Box sx={{height:50,width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
           <Box sx={{height:15,width:50,backgroundColor:'#04afaf',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <CheckIcon sx={{height:13,width:13,color:'#fff'}}/> <Typography  sx={{color:'#fff',fontSize:9,ml:0.5,mt:0.5,fontWeight:'600'}}>RERA</Typography>
      </Box>
            <FavoriteBorderIcon sx={{height:25,width:25,color:'#fff',mr:1}}/>
     </Box>
  
     </Box>
 
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1,ml:1}}>
       Commericial land near airport
     </Typography>
     <Typography  sx={{mt:0.8,color:'grey',fontWeight:'500',fontSize:12,textAlign:'left',ml:1}}>
       Rajiv Nagar South, Mhada Colony, Viman Nagar, Pune, Maharashtra - 411014
     </Typography>
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1,ml:1}}>
       30L / 1000sqft
     </Typography>

     <Button variant="contained" disableElevation size="small" startIcon={<CallIcon/>} sx={{ml:1,mt:1,mb:1,fontSize:11}}>View Number</Button>
   </Paper>
         </Box>
            ))
          }
         

                  </Box>


          
             <Typography sx={{ fontSize: 18, fontWeight: "550",fontFamily:`"Roboto", "Helvetica", "Arial", sans-serif`,mb:1}}>PG for boys/girl</Typography>
                  <Box
                            sx={{
                              display: "flex",
                              maxWidth:'100%',
                              flexDirection: "row",
                              mt: 1,
                              mb: 2, 
                              overflowX: "scroll",
                              scrollbarWidth: "none",
                            }}
                        >
          {
            [1,2,3,4].map((e)=>(
      <Box  onClick={()=>window.open('/Properties/:location')} sx={{minHeight:250,m:1,width:260}}>
     <Paper elevation={1} sx={{minHeight:310,width:260 }}>
     <Box sx={{height:150,width:'100%',borderRadius:2,backgroundImage: `url(${pg})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
     
     <Box sx={{height:50,width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
           <Box sx={{height:15,width:50,backgroundColor:'#04afaf',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <CheckIcon sx={{height:13,width:13,color:'#fff'}}/> <Typography  sx={{color:'#fff',fontSize:9,ml:0.5,mt:0.5,fontWeight:'600'}}>RERA</Typography>
      </Box>
            <FavoriteBorderIcon sx={{height:25,width:25,color:'#fff',mr:1}}/>
     </Box>
  
     </Box>
 
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1,ml:1}}>
       PG near airport
     </Typography>
     <Typography  sx={{mt:0.8,color:'grey',fontWeight:'500',fontSize:12,textAlign:'left',ml:1}}>
       Rajiv Nagar South, Mhada Colony, Viman Nagar, Pune, Maharashtra - 411014
     </Typography>
     <br/>
     <Button variant="contained" disableElevation size="small" startIcon={<CallIcon/>} sx={{ml:1,mt:1,mb:1,fontSize:11,backgroundColor:'#ab266c'}}>View Number</Button>
   </Paper>
         </Box>
            ))
          }
         

                  </Box>





{
  //// search drawer
}

<Box>
<Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: '100%',
            overflow: 'visible',
          },
        }}
      />
      <SwipeableDrawer
       // container={container}
        anchor="bottom"
        open={this.state.open}
        onClose={this.toggleDrawerr}
        onOpen={this.toggleDrawer}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
  
        </StyledBox>


        <StyledBox sx={{  pb: 2, height: '100%', overflow: 'auto' }}>
          
          <Box sx={{height:120,width:'100%',backgroundColor:'#09488b'}}>

             <br/>
             <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>

               <Box sx={{height:47,width:260,backgroundColor:'#032851',pt:2,ml:2,borderRadius:3}}>
               <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',mt:-1.1}}>
                
                <Box sx={{height:33,width:60,backgroundColor:'#fff',mr:0.5,ml:0.5,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:2}}>
                  <Typography sx={{fontSize:12,fontWeight:'bold'}}>Buy</Typography>
                </Box>

                <Box sx={{height:33,width:80,backgroundColor:'#032851',mr:0.5,ml:0.5,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:2}}>
                  <Typography sx={{fontSize:12,fontWeight:'bold',color:'#fff'}}>Rent/PG</Typography>
                </Box>

                <Box sx={{height:33,width:120,backgroundColor:'#032851',ml:0.5,mr:0.5,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:2}}>
                  <Typography sx={{fontSize:12,fontWeight:'bold',color:'#fff'}}>Commercial</Typography>
                </Box>

               </Box>

              </Box> 

            <Paper onClick={()=>this.setState({open:false})} sx={{height:30,width:30,backgroundColor:'transparent',borderRadius:5,mr:2,mt:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
               <CloseIcon sx={{color:"#fff"}}/>
             </Paper>

             </Box>
          


            <Paper
                  elevation={2}
                  variant="outlined"
                  sx={{
                   // width: "95%",
                    height: 47,
                    borderRadius: 2,
                   // border: 0.04,
                   // borderColor: "#33abdf",
                    ml:2,
                    mr:2,
                    mt:3,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      ml: 1,
                      height: 47,
                      width: "100%",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <SearchIcon sx={{ color: "grey", mr: 1 }} />
                    <TextField variant="standard" placeholder='Search Property Location' InputProps={{disableUnderline:true,style:{fontSize:13,fontWeight:'500',color:'#09488b'}}} />
                  </Box>
                   
                   <Box sx={{display:'flex',flexDirection:'row'}}>
                      <MyLocationIcon sx={{ mr: 1, color: "#33abdf" }} /> 
                      <MicIcon sx={{ mr: 1, color: "#33abdf" }} />
                   </Box>
                 
            </Paper>

          </Box>
          


<Box sx={{mt:4,p:2,display:'flex'}}>

<Button variant="outlined" size='small' sx={{fontSize:13,mr:1,width:'auto'}} startIcon={<AddIcon />}>
  Hinjewadi
</Button>
<Button variant="outlined" size='small' sx={{fontSize:13,mr:1,width:'auto'}} startIcon={<AddIcon />}>
  Wakad
</Button>

</Box>

<Box sx={{mt:0,pl :2,pr:2,display:'flex'}}>
<Button variant="outlined" size='small' sx={{fontSize:13,mr:1,width:'auto'}} startIcon={<AddIcon />}>
  Hinjewadi
</Button>
<Button variant="outlined" size='small' sx={{fontSize:13,mr:1,width:'auto'}} startIcon={<AddIcon />}>
  Wakad
</Button>
</Box>
             
      
<Paper elevation={2} sx={{height:60,display:'flex',position:'absolute',bottom:0,left:0,right:0,justifyContent:'right',alignItems:'center'}}>
<Button onClick={()=>this.setState({open:false},()=>{
  window.open('/Properties/:location')
})} variant="contained" sx={{height:30,mr:2}}>Search</Button>
</Paper>


        </StyledBox>
      </SwipeableDrawer>
    </Root>
</Box>





            </Container>
          </Box>
        </Box>
      </div>
    );
  }
}

export default LandingPage;

const currencies = [
  {
    value: "PUNE",
    label: "PUNE",
  },
  {
    value: "MUMBAI",
    label: "MUMBAI",
  },
  {
    value: "KOLKATTA",
    label: "KOLKATA",
  },
  {
    value: "NOIDA",
    label: "NOIDA",
  },
];
