import { Box ,Card,Paper,Typography,Button} from '@mui/material';
import React, { Component } from 'react'
import Carousel from "react-multi-carousel";
import img from "../assets/imgtwo.webp"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import "react-multi-carousel/lib/styles.css";
import one from '../img/1.jpg'
import two from '../img/2.jpg'
import three from '../img/3.jpg'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CheckIcon from '@mui/icons-material/Check';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
export class Scroll extends Component {
  render() {
    return (
      <div>
      <Carousel
      
      additionalTransfrom={1}
      arrows
      autoPlay
      autoPlaySpeed={2000}
      centerMode={false}
      className=""
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024
          },
          items: 3,
          partialVisibilityGutter: 40
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0
          },
          items: 1,
          partialVisibilityGutter: 30
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464
          },
          items:2,
          partialVisibilityGutter: 30
        }
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >



     <Box sx={{minHeight:250,m:1}}>
     <Paper elevation={0} sx={{minHeight:250,width:'100%' }}>
     <Box sx={{height:150,width:'100%',borderRadius:2,backgroundImage: `url(${one})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
     
     <Box sx={{height:50,width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
           <Box sx={{height:15,width:50,backgroundColor:'#04afaf',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <CheckIcon sx={{height:13,width:13,color:'#fff'}}/> <Typography  sx={{color:'#fff',fontSize:9,ml:0.5,mt:0.5,fontWeight:'600'}}>RERA</Typography>
      </Box>
            <FavoriteBorderIcon sx={{height:25,width:25,color:'#fff',mr:1}}/>
     </Box>
  
     </Box>
 
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1}}>
       FLATS VIMAN NAGAR - PUNE
     </Typography>
     <Typography  sx={{mt:0.8,color:'grey',fontWeight:'500',fontSize:12,textAlign:'left'}}>
       Rajiv Nagar South, Mhada Colony, Viman Nagar, Pune, Maharashtra - 411014
     </Typography>
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1}}>
       ₹ 4.8L - 8L
     </Typography>
   </Paper>
     </Box>



     <Box sx={{minHeight:250,m:1}}>
     <Paper elevation={0} sx={{minHeight:250,width:'100%' }}>
     <Box sx={{height:150,width:'100%',borderRadius:2,backgroundImage: `url(${two})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
     
     <Box sx={{height:50,width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
           <Box sx={{height:15,width:50,backgroundColor:'#04afaf',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <CheckIcon sx={{height:13,width:13,color:'#fff'}}/> <Typography  sx={{color:'#fff',fontSize:9,ml:0.5,mt:0.5,fontWeight:'600'}}>RERA</Typography>
      </Box>
            <FavoriteBorderIcon sx={{height:25,width:25,color:'#fff',mr:1}}/>
     </Box>
  
     </Box>
 
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1}}>
       FLAYS VIMAN NAGAR - PUNE
     </Typography>
     <Typography  sx={{mt:0.8,color:'grey',fontWeight:'500',fontSize:12,textAlign:'left'}}>
       Rajiv Nagar South, Mhada Colony, Viman Nagar, Pune, Maharashtra - 411014
     </Typography>
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1}}>
       ₹ 4.8L - 8L
     </Typography>
   </Paper>
     </Box>



     <Box sx={{minHeight:250,m:1}}>
     <Paper elevation={0} sx={{minHeight:250,width:'100%' }}>
     <Box sx={{height:150,width:'100%',borderRadius:2,backgroundImage: `url(${three})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
     
     <Box sx={{height:50,width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
           <Box sx={{height:15,width:50,backgroundColor:'#04afaf',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <CheckIcon sx={{height:13,width:13,color:'#fff'}}/> <Typography  sx={{color:'#fff',fontSize:9,ml:0.5,mt:0.5,fontWeight:'600'}}>RERA</Typography>
      </Box>
            <FavoriteBorderIcon sx={{height:25,width:25,color:'#fff',mr:1}}/>
     </Box>
  
     </Box>
 
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1}}>
      FLATS VIMAN NAGAR - PUNE
     </Typography>
     <Typography  sx={{mt:0.8,color:'grey',fontWeight:'500',fontSize:12,textAlign:'left'}}>
       Rajiv Nagar South, Mhada Colony, Viman Nagar, Pune, Maharashtra - 411014
     </Typography>
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1}}>
       ₹ 4.8L - 8L
     </Typography>
   </Paper>
     </Box>


    
     <Box sx={{minHeight:250,m:1}}>
     <Paper elevation={0} sx={{minHeight:250,width:'100%' }}>

     <Box sx={{height:150,width:'100%',borderRadius:2,backgroundImage: `url(${one})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
     
     <Box sx={{height:50,width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
           <Box sx={{height:15,width:50,backgroundColor:'#04afaf',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <CheckIcon sx={{height:13,width:13,color:'#fff'}}/> <Typography  sx={{color:'#fff',fontSize:9,ml:0.5,mt:0.5,fontWeight:'600'}}>RERA</Typography>
      </Box>
            <FavoriteBorderIcon sx={{height:25,width:25,color:'#fff',mr:1}}/>
     </Box>
  
     </Box>
 
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1}}>
      FLATS VIMAN NAGAR - PUNE
     </Typography>
     <Typography  sx={{mt:0.8,color:'grey',fontWeight:'500',fontSize:12,textAlign:'left'}}>
       Rajiv Nagar South, Mhada Colony, Viman Nagar, Pune, Maharashtra - 411014
     </Typography>
     <Typography  sx={{fontSize:15,fontWeight:'600',color:'#0f1f42',textAlign:'left',mt:1}}>
       ₹ 4.8L - 8L
     </Typography>
   </Paper>
     </Box>

     
     
  



    </Carousel>
      
      
      </div>
    )
  }
}

export default Scroll