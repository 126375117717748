import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import { Paper,Box,Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Appbar from '../Views/Appbar'
const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14}}>
    FAQs
    </Typography>,
  ];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgb(224,245,254)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Faqs() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Appbar/>
<Box sx={{backgroundColor:'#f1f4f6'}}>
<Box sx={{mt:6,height:80,paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
<br/>
<Container maxWidth='xl'>
<Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
</Container>


</Box>
</Box>

    <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
    <Container maxWidth='xl'>
    <Box sx={{backgroundColor:"#00337b",minHeight:110,}}>
    <Box>
    <br/>
    <Typography align='left' sx={{color:"white",fontSize:25,marginLeft:5,fontFamily:'sans-serif'}}>Frequently Asked Questions
    </Typography>
    
    </Box>
    </Box>



    <br/>
    <Paper elevation={1} variant="outlined" square >
<Box>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:'white'}}>
          <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>1. Whats service REALESTATE INDIA provide?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor:'white'}}>
          <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#1c446a',fontSize:{xs:14,sm:14,md:16}}}>
           REALESTATE-INDIA provide property related services such as buy and sell.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:'white'}}>
        <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>2.how we can join REALESTATE INDIA?</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{backgroundColor:'white'}}>
        <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#00337b',fontSize:{xs:14,sm:14,md:16}}}>
       User can apply on our websites
        </Typography>
      </AccordionDetails>
      </Accordion>












  
      </Box>
      </Paper>
      <br/>
      </Container>
      
      
      </Box>
    </div>
  );
}






