import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Main from './Views/Main';
import Test from './Test';




function App() {
  return (
    <div>
 <Main/>
    </div>
  );
}

export default App;
