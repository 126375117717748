import React, { Component } from "react";
import { Container, Box, Grid, Card, Typography, Button,IconButton, Paper, Divider ,Avatar, AppBar} from "@mui/material";

import main_img from '../assets/svt.jpg'
import main_video from '../assets/hd.mp4'
import LayersIcon from '@mui/icons-material/Layers';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import rr from "../img/one.jpeg";
import d from "../img/d.webp";
import l from "../img/l.webp";
import HomeIcon from "@mui/icons-material/Home";
import property from "../img/property.webp";
import Appbar from "./Appbar";


export class PropertyDescription extends Component {
  render() {
    return (
      <div>
        <Appbar/>
        <Box sx={{ minHeight: 800, width: "100%" }}>
          <Container maxWidth="xl">
            <br />
            <br />
            <br />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    minHeight: 300,
                    width: "100%",
                    // backgroundColor: "#fff",
                  }}
                >
                  <Grid container spacing={1}>

                    <Grid item xs={12} sm={7}>
                      <Card
                        sx={{
                          height: 300,
                          width: "100%",
                          backgroundColor: "#fff",
                          backgroundImage:`url(${main_img})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition:'center',
                          backgroundSize:'cover',
                          borderRadius: 1,
                          boxShadow:`inset 0 -70px 70px -40px rgba(0,0,0,1);`
                        }}
                      >



<Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',mt:34}}>
  <Box>
    <Typography sx={{fontSize:13,fontWeight:'700',color:'#fff',ml:2}}>All Photos & Videos</Typography>
  </Box>


  <Box sx={{display:'flex',flexDirection:'row'}}>
   <LayersIcon sx={{mr:1,color:'#fff'}}/> <Typography sx={{fontSize:13,fontWeight:'700',color:'#fff',mr:2}}> 10</Typography>
  </Box>
</Box>

                      </Card>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <Card
                        sx={{
                          height: 146,
                          width: "100%",
                          backgroundColor: "#fff",
                          border: 1,
                          borderColor: "#e3e5ea",
                          borderRadius: 1,
                        }}
                      >


 <video width="100%" height="146" style={{objectFit:'cover'}} controls >
      <source src={main_video} type="video/mp4"/>
</video>


 </Card>


                      <Card
                        sx={{
                          height: 147,
                          width: "100%",
                          backgroundColor: "#fff",
                          backgroundImage:`url(${main_img})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition:'center',
                          backgroundSize:'cover',
                          border: 1,
                          borderColor: "#e3e5ea",
                          borderRadius: 1,
                          mt:0.8,
                          boxShadow:`inset 0 -50px 50px -40px rgba(0,0,0,1);`
                        }}
                      >


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',mt:15}}>
  <Box>
    <Typography sx={{fontSize:12,fontWeight:'700',color:'#fff',ml:2,textTransform:'uppercase'}}>Outdoors</Typography>
  </Box>


  <Box sx={{display:'flex',flexDirection:'row'}}>
   <LayersIcon sx={{mr:1,color:'#fff'}}/> <Typography sx={{fontSize:12,fontWeight:'700',color:'#fff',mr:2}}> 10</Typography>
  </Box>
</Box>




                      </Card>
                    </Grid>

                  </Grid>



<Box sx={{display:'flex',flexDirection:'row',mt:2}}>

  <Box sx={{height:50,width:90,border:0.5,borderColor:'#f2f3f5',display:'flex',justifyContent:'center',alignItems:'center'}}>
     <img style={{height:40,width:90,objectFit:'contain'}} src="https://newprojects.99acres.com/projects/arham_realty/arhams_fusion_park/pnyq6ul_1733289200_535326735_O.jpg"/>
  </Box>

<Box sx={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
  <Typography sx={{fontSize:19,fontWeight:'600',ml:2,mt:1}}>Royal Arcade Kondhwa <IconButton color="primary" aria-label="add to shopping cart">
  <FavoriteBorderIcon />
</IconButton></Typography>
  <Button sx={{height:34,fontSize:11,mt:1,fontWeight:'bold'}} variant="contained" size="small">Contact Builder</Button>
</Box>

</Box>



<Box sx={{display:'flex',flexDirection:'row',mt:2}}>

  <Box sx={{height:20,width:60,backgroundColor:'#0cb5b0',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',mr:1}}>
      <DoneAllIcon sx={{height:15,width:15,color:'#fff'}}/> <Typography sx={{fontSize:10,fontWeight:'600',color:'#fff',ml:0.5}}>RERA</Typography>
  </Box>

  <Box sx={{height:20,width:90,backgroundColor:'#d7f2e3',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',mr:1}}>
 <Typography sx={{fontSize:10,fontWeight:'600',color:'green',ml:0.5}}>No Brokrage</Typography>
  </Box>


  <Box sx={{height:20,width:140,backgroundColor:'#f7f7ff',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',mr:1}}>
      <ViewInArIcon sx={{height:15,width:15,color:'#a6a3c0'}}/> <Typography sx={{fontSize:10,fontWeight:'600',color:'#a6a3c0',ml:0.5}}>3d Floor Plan Available</Typography>
  </Box>

  <Box sx={{height:20,width:110,backgroundColor:'#f6fefe',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',mr:1}}>
      <AddIcon sx={{height:15,width:15,color:'#04afaf'}}/> <Typography sx={{fontSize:10,fontWeight:'600',color:'#04afaf',ml:0.5}}>10 Top Fecilities</Typography>
  </Box>


</Box>


<Box sx={{height:100,backgroundColor:'#f0f9ff',width:'100%',mt:2,borderRadius:1}}>
<Typography sx={{fontSize:12,color:'black',pt:1,ml:1,fontWeight:'600',textTransform:'uppercase'}}>Construction Status</Typography>
<Typography sx={{fontSize:20,color:'black',pt:0.5,ml:1,fontWeight:'600'}}>Under Construction</Typography>
<Typography sx={{fontSize:12,color:'black',ml:1,fontWeight:'500'}}>Completion in Dec, 2027</Typography>
</Box>

<Box sx={{mt:3,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
  <Box>
    <Typography sx={{fontSize:26,fontWeight:'600'}}>₹ 51.06 - 73.54 L</Typography>
    <Typography sx={{fontSize:11,fontWeight:'600',color:'grey',textTransform:'uppercase'}}>PRICE RANGE</Typography>
  </Box>

<Button variant="outlined" startIcon={<FileDownloadIcon/>} size="small" sx={{fontSize:12,height:30}}>Download Brouchers</Button>
</Box>



<Box sx={{mt:2,mb:1,display:'flex',maxWidth:'100%',overflowX:'scroll',scrollbarWidth:'none'}}>

<Box sx={{height:120,width:250,borderRadius:1,mr:1,mb:1}}>
<Paper elevation={1} sx={{height:120,width:250,borderRadius:1,border:1,borderColor:'#e9edee'}}>
<Box sx={{height:35,width:'100%',backgroundColor:'#f7f7f7',display:'flex',alignItems:'center'}}>
<Typography sx={{fontSize:14,fontWeight:'bold',ml:1,color:'#466277'}}>2 BHK Apartment</Typography>
</Box>
<Typography sx={{fontSize:11,ml:1,mt:1}}>Carpet Area</Typography>
<Typography sx={{fontSize:12,ml:1,mt:0.5,fontWeight:'600'}}>620 - 765 sq.ft</Typography>

<Typography sx={{fontSize:15,fontWeight:'bold',mt:1,ml:1}}>₹ 51.06 - 73.54 L</Typography>
</Paper>
</Box>

<Box sx={{height:120,width:250,borderRadius:1,mr:1,mb:1}}>
<Paper elevation={1} sx={{height:120,width:250,borderRadius:1,border:1,borderColor:'#e9edee'}}>
<Box sx={{height:35,width:'100%',backgroundColor:'#f7f7f7',display:'flex',alignItems:'center'}}>
<Typography sx={{fontSize:14,fontWeight:'bold',ml:1,color:'#466277'}}>3 BHK Apartment</Typography>
</Box>
<Typography sx={{fontSize:11,ml:1,mt:1}}>Carpet Area</Typography>
<Typography sx={{fontSize:12,ml:1,mt:0.5,fontWeight:'600'}}>920 - 1165 sq.ft</Typography>

<Typography sx={{fontSize:15,fontWeight:'bold',mt:1,ml:1}}>₹ 61.06 - 93.54 L</Typography>
</Paper>
</Box>


</Box>

<br/>
<Divider/>
<br/>

<Box>
                        <Typography
                          variant="h2"
                          sx={{
                            fontSize: 23,
                            fontWeight: "550",
                            color: "#0a1d3e",
                          }}
                        >
                          Localities you may like
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{
                            fontSize: 12,
                            fontWeight: "500",
                            color: "#0a1d3e",
                          }}
                        >
                          based on the localities you have explored in Pune
                          South
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            overflowX: "scroll",
                            overflowY: "hidden",
                            mt: 2,
                            mb: 1,
                            whiteSpace: "nowrap",
                            scrollbarWidth: "none",
                          }}
                        >
                          {[1, 2, 3, 4, 5, 6, 7].map(() => (
                            <Box sx={{ height: 120, width: 260, mr: 1, mb: 1 }}>
                              <Card
                                elevation={1}
                                sx={{
                                  height: 120,
                                  width: 260,
                                  borderRadius: 2,
                                  mr: 1,
                                  border: 1,
                                  borderColor: "#e9edee",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: 70,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    pl: 2,
                                    pr: 2,
                                  }}
                                >
                                  <Box sx={{ display: "flex" }}>
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={rr}
                                      sx={{ width: 47, height: 47 }}
                                    />
                                  </Box>

                                  <Box sx={{ ml: 1 }}>
                                    <Typography
                                      sx={{
                                        fontWeight: "600",
                                        fontSize: 13,
                                        color: "#233144",
                                      }}
                                    >
                                      Apartment
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: 11,
                                        color: "#233144",
                                      }}
                                    >
                                      ₹ 4,500/sq.ft
                                    </Typography>
                                  </Box>
                                </Box>
                                <Divider />

                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "50%",
                                      height: 50,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: "600",
                                        color: "#1d75d2",
                                      }}
                                    >
                                      Insight
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      height: 35,
                                      width: 2,
                                      backgroundColor: "#e9edee",
                                    }}
                                  ></Box>
                                  <Box
                                    sx={{
                                      width: "50%",
                                      height: 50,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 13,
                                        fontWeight: "600",
                                        color: "#1d75d2",
                                      }}
                                    >
                                      Properties
                                    </Typography>
                                  </Box>
                                </Box>
                              </Card>
                            </Box>
                          ))}
                        </Box>
</Box>


     <Box>
                        <Typography
                          variant="h2"
                          sx={{
                            fontSize: 23,
                            fontWeight: "550",
                            color: "#0a1d3e",
                          }}
                        >
                          Apartments, Villas and more
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{
                            fontSize: 12,
                            fontWeight: "500",
                            color: "#0a1d3e",
                          }}
                        >
                          in south pune
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            overflowX: "scroll",
                            mt: 2,
                            scrollbarWidth: "none",
                          }}
                        >
                          {[
                            { color: "#fef5e4", img: d },
                            { color: "#f0f9ff", img: l },
                            { color: "#d7f2e3", img: property },
                            { color: "#fef5e4", img: d },
                            { color: "#f0f9ff", img: l },
                          ].map((i) => (
                            <Box sx={{ height: 330, width: 250, mr: 1 }}>
                              <Card
                                elevation={1}
                                sx={{
                                  height: 330,
                                  width: 250,
                                  borderRadius: 2,
                                  backgroundColor: i.color,
                                  backgroundImage: `url(${i.img})`,
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: 24,
                                    fontWeight: "800",
                                    textAlign: "center",
                                    mt: 5,
                                    color: "#445368",
                                  }}
                                >
                                  Residencial
                                  <br />
                                  Land
                                </Typography>
                              </Card>
                            </Box>
                          ))}
                        </Box>
    </Box>

<br/>
<br/>

                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <Card
                  sx={{ minHeight: 300, width: "100%",backgroundColor:'#f3f6ff'}}
                > {
                  /// WorkspacesIcon
                  }

                <Typography sx={{fontSize:20,fontWeight:'bold',padding:2}}>Why you should consider Royal Arcade Kondhwa?</Typography>

                <Box sx={{display:'flex',flexDirection:'row',ml:2,mr:2}}>
                <WorkspacesIcon sx={{height:14,width:14,mt:0.3}}/> <Typography sx={{fontSize:13,ml:1}}>EV Charging available</Typography>
                </Box>
                
                <Box sx={{display:'flex',flexDirection:'row',ml:2,mr:2,mt:1}}>
                <WorkspacesIcon sx={{height:14,width:14,mt:0.3}}/> <Typography sx={{fontSize:13,ml:1}}>Decorative entrance lobby</Typography>
                </Box>

                <Box sx={{display:'flex',flexDirection:'row',ml:2,mr:2,mt:1}}>
                <WorkspacesIcon sx={{height:14,width:14,mt:0.3}}/> <Typography sx={{fontSize:13,ml:1}}>Decorative entrance lobby</Typography>
                </Box>

                <Box sx={{display:'flex',flexDirection:'row',ml:2,mr:2,mt:1}}>
                <WorkspacesIcon sx={{height:14,width:14,mt:0.3}}/> <Typography sx={{fontSize:13,ml:1}}>Decorative entrance lobby</Typography>
                </Box>

                <Box sx={{display:'flex',flexDirection:'row',ml:2,mr:2,mt:1,mb:1}}>
                <WorkspacesIcon sx={{height:14,width:14,mt:0.3}}/> <Typography sx={{fontSize:13,ml:1}}>Vast passages for light, ventilation & effortless movement</Typography>
                </Box>

               <Divider/>

<Typography sx={{fontSize:11,mt:2,ml:2}}>Devloped by</Typography>
<Typography sx={{fontSize:15,ml:2,fontWeight:'800',color:'black',mt:0.5}}>LJMK DEVELOPERS LLP</Typography>

<Box sx={{display:'flex',flexDirection:'row',ml:2,mt:1}}>
  <Button variant="contained" sx={{height:30,fontSize:11,mr:1}}>
   vIew Number
  </Button>

  <Button variant="outlined" sx={{height:30,fontSize:11}}>
    Contact Builder
  </Button>

</Box>

<br/>
                  
                </Card>
              </Grid>
            </Grid>
          </Container>





        </Box>
      </div>
    );
  }
}

export default PropertyDescription;

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: "Fern",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
  },
];
